import { render, staticRenderFns } from "./liveList.vue?vue&type=template&id=5f037c44&scoped=true&"
import script from "./liveList.vue?vue&type=script&lang=js&"
export * from "./liveList.vue?vue&type=script&lang=js&"
import style0 from "./liveList.vue?vue&type=style&index=0&id=5f037c44&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f037c44",
  null
  
)

export default component.exports