<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <div>
            <van-pull-refresh class="liveList" v-model="refreshing" @refresh="onRefresh">
                <van-search
                        class="custom_search"
                        v-model="listQuery.name"
                        left-icon=""
                        background="transparent"
                        placeholder="请输入直播名称"
                        @search="onSearch">
                    <template slot="right-icon">
                        <van-icon @click="onSearch" name="search"/>
                    </template>
                </van-search>
                <div class="van_tabs_layout">
                    <van-tabs v-model="listQuery.type" @change="onSearch">
                        <van-tab title="课堂" :name="liveTypeKey.classroom"/>
                        <van-tab title="会议" :name="liveTypeKey.meeting"/>
                        <van-tab title="活动" :name="liveTypeKey.activity"/>
                    </van-tabs>
                </div>
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad">
                    <BarList :list="list" @callMore="(item)=>{callMore(item, '/liveDetails')}"/>
                </van-list>
            </van-pull-refresh>
        </div>
    </baseContainer>
</template>

<script>
import HeadNav from './../../components/headNav';
import BarList from './components/BarList';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import api from './../../api/home';
import constant from './../../utils/constant';

export default {
    name: "liveList",
    components: {
        HeadNav,
        BarList
    },
    mixins: [DropDownRefresh],
    data () {
        return {
            liveTypeKey: constant.liveTypeKey,
            listQuery: {
                type: constant.liveTypeKey.classroom,
                name: '',
                current: 1,
                size: 10
            }
        }
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url);
        },
        // 查询首页直播列表
        getList () {
            api.getQueryHomeList(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    }
}
</script>

<style scoped lang="less">
    .liveList {
        min-height: 80vh;
        padding: 20px 40px 40px;
        background: #FCFCFC;
    }
</style>